<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? "Edit" : "Add a new" }} Note
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="note-form">
          <v-textarea
            label="Notes"
            v-model="fields.notes"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('notes')"
            :error-messages="errors['notes']"
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="note-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      fields: {
        notes: null,
      },
      errors: {},
    };
  },

  methods: {
    open(property = null) {
      if (property !== null) {
        this.property = property;
        this.fields.notes = property.notes;
        this.isEditing = true;
      }

      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        propertyId: this.$route.params.propertyId,
        fields: this.fields,
      };

      this.$store
        .dispatch("melrose/propertiesStore/saveNotes", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.price = {};
      this.fields = {
        notes: null,
      };
      this.$refs.formWrapper.scrollTop = 0;
    },
  },
};
</script>
