<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="6">
          <features-dialog ref="featuresDialog" :property="property" />
        </v-col>

        <v-col cols="6">
          <v-card outlined class="mb-6">
            <v-card-subtitle
              class="d-flex justify-start align-center grey lighten-5"
            >
              <div class="flex-grow-1">Full Property Description</div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.descriptionDialog.open(property)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Description</span>
              </v-tooltip>
            </v-card-subtitle>
            <v-divider></v-divider>
            <v-simple-table>
              <tbody>
                <tr>
                  <th>Full Description</th>
                  <td v-html="property.description"></td>
                </tr>
                <tr>
                  <th>Externally</th>
                  <td v-html="property.externally"></td>
                </tr>
                <tr>
                  <th>Location</th>
                  <td v-html="property.location"></td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <notes-dialog ref="notesDialog" />
    <description-dialog ref="descriptionDialog" />
  </div>
</template>

<script>
import featuresDialog from "./components/FeaturesDialog.vue";
import notesDialog from "./components/NotesDialog.vue";
import descriptionDialog from "./components/DescriptionDialog.vue";
export default {
  components: {
    featuresDialog,
    notesDialog,
    descriptionDialog,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Properties",
          disabled: false,
          to: { name: "module-melrose-properties-all" },
          exact: true,
        },
      ],
      varietiesDialog: {
        open: false,
        isEditing: false,
        name: {},
        fields: {
          name: null,
        },
      },
      errors: {},
      fields: {
        property_features: [],
      },
      featuresTableHeaders: [
        { text: "Name", value: "name" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
    };
  },

  computed: {
    property() {
      return this.$store.getters["melrose/propertiesStore/get"];
    },
  },

  methods: {
    downloadPdf() {},
  },

  created() {
    this.breadcrumbs.push({
      text: this.property.address.short,
      disabled: true,
    });
  },
};
</script>
