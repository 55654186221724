<template>
  <div>
    <v-card outlined class="mb-6">
      <v-card-subtitle class="d-flex justify-start align-center grey lighten-5">
        <div class="flex-grow-1">Property Features</div>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              depressed
              color="green lighten-4 green--text"
              v-on="on"
              @click="openVarietyDialog()"
            >
              <v-icon small>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Add Feature</span>
        </v-tooltip>
      </v-card-subtitle>
      <v-divider></v-divider>

      <v-form @submit.prevent="save" method="post" id="features-form">
        <v-data-table
          :headers="varietiesTable.headers"
          :items="featuresWithIndex"
          :disable-pagination="true"
          :hide-default-footer="true"
          no-data-text="There are no features"
        >
          <template v-slot:top>
            <v-alert
              type="error"
              dense
              col="4"
              rounded="0"
              class="mb-0"
              v-if="errors.hasOwnProperty('varieties')"
              >{{ errors["varieties"].join(", ") }}</v-alert
            >
            <v-divider></v-divider>
          </template>

          <template v-slot:item.variety="{ item }">
            <div style="white-space: pre-line" v-html="item.variety"></div>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  x-small
                  text
                  icon
                  color="blue"
                  v-on="on"
                  class="mr-2"
                  @click="openVarietyDialog(item)"
                >
                  <v-icon x-small>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  x-small
                  text
                  icon
                  color="red"
                  v-on="on"
                  @click="deleteVariety(item.index)"
                >
                  <v-icon x-small>mdi-archive</v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-form>

      <v-divider></v-divider>
    </v-card>

    <v-dialog
      v-model="varietiesDialog.open"
      max-width="400"
      @click:outside="resetVarietyDialog()"
    >
      <v-card>
        <v-card-title class="headline"
          >{{
            varietiesDialog.isEditing ? "Edit" : "New"
          }}
          Feature</v-card-title
        >
        <v-card-text class="pt-6 pb-0">
          <v-text-field
            label="New Feature *"
            v-model="varietiesDialog.fields.name"
            outlined
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="resetVarietyDialog()"
            >Close</v-btn
          >
          <v-btn
            color="accent"
            text
            type="submit"
            :loading="loading"
            @click="saveVarietyDialog()"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    property: {
      required: true,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      fields: {
        property_features: [],
      },
      varietiesDialog: {
        open: false,
        isEditing: false,
        variety: {},
        fields: {
          variety: null,
        },
      },
      varietiesTable: {
        headers: [
          { text: "Name", value: "name", sortable: false },
          {
            text: "Actions",
            value: "actions",
            sortable: false,
            align: "right",
          },
        ],
        dialog: {
          open: false,
          loading: false,
        },
      },
      errors: {},
    };
  },

  mounted() {
    if (this.property.property_features) {
      this.fields.property_features = this.property.property_features;
    } else {
      this.fields.property_features = [];
    }
  },

  computed: {
    featuresWithIndex() {
      return this.fields.property_features.map((name, index) => ({
        ...name,
        index,
      }));
    },
  },

  methods: {
    open(property = null) {
      if (property !== null) {
        this.property = property;
        this.fields.property_features = property.property_features;
        this.isEditing = true;
      }

      this.dialog = true;
    },

    openVarietyDialog(name = null) {
      if (name !== null) {
        this.varietiesDialog.isEditing = true;
        this.varietiesDialog.name = name;
        this.varietiesDialog.fields.name = name.name;
      }

      this.varietiesDialog.open = true;
    },

    resetVarietyDialog() {
      this.loading = false;
      this.varietiesDialog.open = false;
      this.varietiesDialog.isEditing = false;
      this.varietiesDialog.name = {};
      this.varietiesDialog.fields.name = null;
    },

    deleteVariety(index) {
      this.fields.property_features.splice(index, 1);

      let payload = {
        appId: this.$route.params.id,
        fields: this.fields,
      };

      payload.propertyId = this.property.id;

      this.$store
        .dispatch("melrose/propertiesStore/savePropertyFeatures", payload)
        .then(() => {
          this.resetVarietyDialog();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    saveVarietyDialog() {
      this.loading = true;
      if (this.varietiesDialog.isEditing) {
        this.fields.property_features[this.varietiesDialog.name.index].name =
          this.varietiesDialog.fields.name;
      } else {
        this.fields.property_features.push({
          name: this.varietiesDialog.fields.name,
        });
      }

      let payload = {
        appId: this.$route.params.id,
        fields: this.fields,
      };

      payload.propertyId = this.property.id;

      this.$store
        .dispatch("melrose/propertiesStore/savePropertyFeatures", payload)
        .then(() => {
          this.resetVarietyDialog();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },
  },
};
</script>
